<template>
  <b-modal @hide="onHide" :centered="true" ref="modal-reply" hide-footer title="Trả lời câu hỏi"  class="modal-reply">
    <div class="d-block">
      <ValidationObserver ref="form">
        <form class="form" @submit.prevent="reply">
          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <label >Câu hỏi: </label>
                <div style="border: 1px solid #E4E6EF; border-radius: 0.42rem;">
                  <p style="margin: 5px 12px 5px 12px; font-style: italic">{{ qa.question }}</p>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <label>Nhập câu trả lời: <span class="text-danger">*</span></label>
                <ValidationProvider name="Nhập câu trả lời" rules="required" v-slot="{ errors,classes }" vid="answer">
                  <textarea name="answer" v-model="qa.answer" class="form-control" placeholder="Nhập câu trả lời..." style="min-height: 150px"></textarea>
                  <div class="fv-plugins-message-container">
                    <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }} </div>
                  </div>
                </ValidationProvider>
              </div>
            </div>
          </div>
        </form>
      </ValidationObserver>
    </div>
    <footer class="modal-footer">
      <button type="button" class="btn btn-secondary" @click="hideModal">Hủy bỏ</button>
      <button type="button" class="btn btn-success" @click="reply">Lưu</button>
    </footer>
  </b-modal>
</template>
<script>
    import {UPDATE_QA} from "../../../core/services/store/center/QA.module";

    export default {
        components: {

        },
        props: {
            showModal: { default: false },
            qa: { default: false },
        },
        name: "Reply",
        data() {
            return {
                title: '',
            }
        },
        mounted() {
            this.clearForm();
        },
        methods: {
            onHide(evt) {
                this.$emit("clicked", true);
            },
            clearForm() {
                this.qa.answer = '';
            },
            hideModal() {
                this.$refs['modal-reply'].hide();
            },
            toggleModal() {
                // when the modal has hidden
                this.$refs['modal-reply'].toggle('#toggle-btn');
            },
            reply() {
                this.$refs.form.validate().then(success => {
                    if (!success) {
                        return false;
                    } else {
                        this.$store.dispatch(UPDATE_QA, this.qa).then((res) => {
                            if (!res.error) {
                                this.hideModal();
                                this.$emit('completed', {
                                    id: res.data.id,
                                    is_reply : res.data.is_reply
                                });
                            } else {
                                this.$bvToast.toast(res.message, {
                                    title: 'Trả lời câu hỏi',
                                    variant: 'danger',
                                    solid: true
                                });
                            }}).catch((response) => {
                                if (response?.data?.data?.message_validate_form) {
                                    this.$refs.form.setErrors(response.data.data.message_validate_form);
                                    return;
                                }
                                this.$bvToast.toast('Có lỗi xảy ra', {
                                    title: 'Trả lời câu hỏi',
                                    variant: 'danger',
                                    solid: true
                                });
                        });
                    }
                });
            },
        },
        watch: {
            showModal() {
                if (this.showModal) {
                    if (this.qa.is_reply != 1) {
                        this.clearForm();
                    }
                    this.$refs['modal-reply'].show();
                }
            }
        }

    }
</script>
